import React from 'react'
import { graphql } from 'gatsby'
import Layout from './layout'
import { CustomSections, Seo, AreasSection } from '../components'
import PropTypes from 'prop-types'

 const Page = ({ data }) => {
  const page = data.allStrapiFjsPages.nodes[0]
  const {body, title} = page

  return (
    <Layout>
      <Seo title={title} />
      <AreasSection />
      <div className="primary-background">
        <div className="container py-5">
          <CustomSections sections={body} />
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
query($slug: String!) {
  allStrapiFjsPages (filter: { slug: { eq: $slug } }) {
    nodes {
      title
      slug
      body
      banner {
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
}
`
export default Page

Page.propTypes = {
  data: PropTypes.array
}

